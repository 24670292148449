@import url(https://cdn.jsdelivr.net/npm/reset-css@5.0.1/reset.min.css);
input {
    all: unset;
    -webkit-appearance: none;
            appearance: none;
    box-sizing: border-box;
}

* {
    box-sizing: border-box;
}

body {
    background-color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    color: white;
}

button {
    background-color: white;
    color: black;
}

a {
    text-decoration: none;
    color: inherit;
}

form {
    width: 100%;
}

.nweet-input::-webkit-input-placeholder {
    color: white;
    opacity: 0.9;
}

.nweet-input:-ms-input-placeholder {
    color: white;
    opacity: 0.9;
}

.nweet-input::placeholder {
    color: white;
    opacity: 0.9;
}

.container {
    width: 100%;
    max-width: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.formInput {
    width: 100%;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid black;
    text-align: center;
    background-color: white;
    color: black;
}

.formBtn {
    cursor: pointer;
    width: 100%;
    padding: 7px 20px;
    text-align: center;
    color: white;
    border-radius: 20px;
    background-color: #04aaff;
    cursor: pointer;
}

.cancelBtn {
    cursor: pointer;
    background-color: tomato;
}

/* Profile Page */

.profileForm {
    border-bottom: 1px solid rgba(255, 255, 255, 0.9);
    padding-bottom: 30px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.logOut {
    margin-top: 50px;
}

/* Nwitter Factory */

.factoryForm {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.factoryInput__container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    position: relative;
    margin-bottom: 20px;
    width: 100%;
}

.factoryInput__input {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    height: 40px;
    padding: 0px 20px;
    color: white;
    border: 1px solid #04aaff;
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
}

.factoryInput__arrow {
    position: absolute;
    right: 0;
    background-color: #04aaff;
    height: 40px;
    width: 40px;
    padding: 10px 0px;
    text-align: center;
    border-radius: 20px;
    color: white;
    box-sizing: border-box;
}

.factoryInput__label {
    color: #04aaff;
    cursor: pointer;
}

.factoryInput__label span {
    margin-right: 10px;
    font-size: 12px;
}

.factoryForm__attachment {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.factoryForm__attachment img {
    height: 80px;
    width: 80px;
    border-radius: 40px;
}

.factoryForm__clear {
    color: #04aaff;
    cursor: pointer;
    text-align: center;
}

.factoryForm__clear span {
    margin-right: 10px;
    font-size: 12px;
}

/* Nweet */

.nweetEdit .formBtn {
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 5px;
}

.nweet {
    margin-bottom: 20px;
    background-color: white;
    width: 100%;
    max-width: 320px;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    color: rgba(0, 0, 0, 0.8);
}

.nweet h4 {
    font-size: 14px;
    max-width: 280px;
}

.nweet img {
    right: -10px;
    top: 20px;
    position: absolute;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-top: 10px;
}

.nweet__actions {
    position: absolute;
    right: 10px;
    top: 10px;
}

.nweet__actions span {
    cursor: pointer;
}

.nweet__actions span:first-child {
    margin-right: 10px;
}

/* Auth */

.authContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100vh;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.authBtns {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    max-width: 320px;
}

.authBtn {
    cursor: pointer;
    border-radius: 20px;
    border: none;
    padding: 10px 0px;
    font-size: 12px;
    text-align: center;
    width: 150px;
    background: white;
    cursor: pointer;
}

/* AuthForm */

.authInput {
    max-width: 320px;
    width: 100%;
    padding: 10px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    font-size: 12px;
    color: black;
    box-sizing: content-box;
}

.authSubmit {
    text-align: center;
    background: #04aaff;
    color: white;
    margin-top: 10px;
    box-sizing: content-box;
    cursor: pointer;
}

.authError {
    color: tomato;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
}

.authSwitch {
    color: #04aaff;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 50px;
    display: block;
    font-size: 12px;
    text-decoration: underline;
}

.profile__img {
    border-radius: 20px;
}
/* 
a:hover + span {
    opacity: 1;
    transition: 0.5s;
} */

